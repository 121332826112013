import React, { Component } from "react"
import LayoutHome from "../components/LayoutHome"
import { graphql } from "gatsby"
import BasicText from "../components/BasicText"
import Slider from "../components/Slider"
import css from "./stylesheets/index.module.scss"
import CommonButton from "../components/CommonButton"
import Seo from "../components/Seo"
import AnimatedLogo from "../components/AnimatedLogo"
import RubyConvert from "../components/RubyConvert"
import ScrollDown from "../components/ScrollDown"
import Img from "gatsby-image"
import IconFb from "../images/icon_fb.svg"

class PageIndex extends Component {
  constructor(props) {
    super(props)
    this.doClickLangButton = this.doClickLangButton.bind(this)
  }

  doClickLangButton() {
    let layoutBasic = this.ref.layoutBasicInstance
    if (typeof layoutBasic !== "undefined" && layoutBasic !== null) {
      layoutBasic.ref.globalNavigationInstance.openLanguageMenu()
    }
  }

  render() {
    let srcList = []
    for (let i = 0; i < 4; i += 1) {
      srcList.push([
        this.props.data["slideSpImage" + (i + 1)].childImageSharp.fluid,
        {
          ...this.props.data["slideImage" + (i + 1)].childImageSharp.fluid,
          media: `(min-width: 835px)`,
        },
      ])
    }

    this.ref = {}

    return (
      <div className={css.topPage}>
        <Seo title={`HOME`} />
        <LayoutHome reference={this.ref} lang={`easyja`}>
          <Slider intro={true}>
            <Img
              fluid={srcList[0]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[1]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[2]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[3]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </Slider>
          <div>
            <section className={css.intro}>
              <h1>
                <AnimatedLogo />
              </h1>
              <div
                style={{
                  position: "absolute",
                  bottom: "2vh",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <ScrollDown to={`#TopContent`} />
              </div>
            </section>
            <BasicText>
              <p>
                <a
                  className={
                    css.facebookLink + ` withoutBlankIcon withoutAnimation`
                  }
                  href={this.props.data.site.siteMetadata.facebookLink}
                  target={`_blank`}
                >
                  <img src={IconFb} alt="facebook" />
                  <span>
                    <RubyConvert>{`Facebook で[最新/さいしん][情報/じょうほう][発信中/はっしんちゅう]`}</RubyConvert>
                  </span>
                </a>
              </p>

              <hr />

              <h2 id={`TopContent`}>WHAT is KAIGO?</h2>
              <p>
                こんにちは！
                <br />
                <RubyConvert>{`{『KAIGO in JAPAN』は、}{[日本/にほん]で}{[介護/かいご]の}{[仕事/しごと]を}{しようと}{[考/かんが]えている}{[人/ひと]のための}{ウェブサイトです。}{[介護/かいご]の[仕事/しごと]や}{[日本/にほん]での}{[生活/せいかつ]について、}{みなさんが}{[気/き]になる}{いろいろな}{ことに}{[答/こた]えたいと}{[思/おも]っています。}{[先輩/せんぱい]たちの}{リアルな}{[話/はなし]も}{[紹介/しょうかい]します。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{[日本/にほん]で}{[働/はたら]いている}{[先輩/せんぱい]たちの}{インタビュー、}{[日本/にほん]と}{[介護/かいご]についての}{[基本/きほん][情報/じょうほう]、}{よく}{ある}{[質問/しつもん]}{などが} {あります。}`}</RubyConvert>
                <RubyConvert>{`{「やさしい[日本語/にほんご]（Easy Japanese）」}{「[日本語/にほんご]」}{「[英語/えいご]」から}{[選/えら]んで、}{ぜひ}{[読/よ]んでください。}`}</RubyConvert>
              </p>
              <p className={`commonButton`}>
                <button onClick={this.doClickLangButton}>
                    <span>LANGUAGE</span>
                </button>
              </p>

              <h3 className={css.Heading3h}>
                <RubyConvert>{`[介護/かいご][施設/しせつ]の1[日/にち]`}</RubyConvert>{" "}
                <small>(3:47)</small>
              </h3>

              <div className={`fixedRatioBox is-16x9`}>
                <iframe
                  src="https://www.youtube.com/embed/i8hNvS-DbTk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="介護施設の1日"
                ></iframe>
              </div>

              <CommonButton to={`/what-is-kaigo/`}>MORE</CommonButton>

              <hr />

              <h2>INTERVIEW</h2>
              <p>
                <RubyConvert>{`{[日本/にほん]で}{[働/はたら]いている}{[人/ひと]たちへの}{インタビューです。}{インタビューは、}{[２人/ふたり]の}{[会話/かいわ]に}{なっています。}{[２人/ふたり]の}{[関係/かんけい]は、[夫婦/ふうふ]や}{[仕事/しごと]の[先輩/せんぱい]・}{[後輩/こうはい]など、}{いろいろです。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{また、[働/はたら]いている}{[施設/しせつ]と}{[暮/く]らしている}{[家/いえ]を}{[見/み]せてくれました。}`}</RubyConvert>
                <br />
              </p>
              <CommonButton to={`/interview/`}>MORE</CommonButton>

              <hr />

              <h2>BASIC INFORMATION</h2>
              <p>
                <RubyConvert>{`{[日本/にほん]は}{どんな}{[国/くに]？}{[日本/にほん]で}{[介護/かいご]の}{[仕事/しごと]を}{するには？}`}</RubyConvert>
                <br />
                <RubyConvert>{`{[日本/にほん]の}{[基本的/きほんてき]な}{[情報/じょうほう]や、[介護/かいご]の}{[仕事/しごと]を}{する}{ための}{4つの}{[在留資格/ざいりゅうしかく]}{などを}{[紹介/しょうかい]します。}`}</RubyConvert>
              </p>
              <CommonButton to={`/basic-information/`}>MORE</CommonButton>

              <hr />

              <h2>FAQ</h2>
              <p>
                <RubyConvert>{`{よく}{ある}{[質問/しつもん]です。}{みなさんの}{[生活/せいかつ]の}{[役/やく]に[立/た]つ}{[団体/だんたい]や、}{サービス}{なども}{[紹介/しょうかい]しています。}`}</RubyConvert>
              </p>
              <CommonButton to={`/faq/`}>MORE</CommonButton>
            </BasicText>
          </div>
        </LayoutHome>
      </div>
    )
  }
}

export default PageIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        facebookLink
      }
    }
    slideImage1: file(relativePath: { eq: "slider/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage1: file(relativePath: { eq: "slider/01_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage2: file(relativePath: { eq: "slider/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage2: file(relativePath: { eq: "slider/02_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage3: file(relativePath: { eq: "slider/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage3: file(relativePath: { eq: "slider/04_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage4: file(relativePath: { eq: "slider/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage4: file(relativePath: { eq: "slider/05_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
